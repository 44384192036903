import Image from "next/image";
import Link from "next/link";

const Header = ({ topLevelMenus, moreItems }) => {
  moreItems = moreItems ?? [];
  topLevelMenus = topLevelMenus ?? [];

  const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU === "true";
  let filter = (q) => q.isAU === true;
  if (!isAU) filter = (q) => q.isNZ === true;

  const moreLinks = moreItems.filter(filter);
  const topLinks = topLevelMenus.filter(filter);
  //console.log("menu", topLevelMenus);

  // //deep level filter
  // topLinks.map((grp) => {
  // 	if (grp.menuGroup__Dynamic != null && grp.menuGroup__Dynamic.childs != null){
  // 		grp.menuGroup__Dynamic.childs = grp.menuGroup__Dynamic.childs.filter(filter);
  // 	}
  // });

  return (
    <header className='page-header bg-secondary'>
      <nav className='navbar navbar-expand-md navbar-light navbar-dark py-0'>
        <div className='container p-0'>
          <Link href='/'>
            <span className='navbar-brand py-0'>
              <Image
                src='/img/footer-logo.svg'
                className='h-auto'
                width={150}
                height={68}
                alt='Ryco Logo'
                layout='raw'
                priority={true}
              />
            </span>
          </Link>
          <div className='d-flex align-items-center'>
            {/* <Link href='/search/-/-'>
              <a className='navbar-search-link d-inline-block d-md-none p-3' title="Filter Search">
                <Image
                  src={require("../../public/icons/product-filter.svg")}
                  layout='raw'
                  className='svg-filter-white'
                  width='20'
                  height='20'
                  alt='Search'
                />
              </a>
            </Link> */}
            <Link
              href='/sitesearch/-'
              className='navbar-search-link d-inline-block d-md-none p-3'
              title='Site Search'
            >
              {/* <a
                className='navbar-search-link d-inline-block d-md-none p-3'
                title='Site Search'
              > */}
              <Image
                src='/icons/search.svg'
                layout='raw'
                className='svg-filter-white'
                width='20'
                height='20'
                alt='Site Search'
                priority={true}
              />
              {/* </a> */}
            </Link>
            <button
              className='navbar-toggler border-0 collapsed p-3 mr-2'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNavAltMarkup'
              aria-controls='navbarNavAltMarkup'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <Image
                src='/icons/bars.svg'
                layout='raw'
                className='svg-filter-white'
                width='20'
                height='20'
                alt='Menu'
                priority={true}
              />
            </button>
          </div>
          <div
            className='collapse navbar-collapse justify-content-between'
            id='navbarNavAltMarkup'
          >
            <ul className='navbar-nav'>
              {topLinks.map(function (topM, idx) {
                if (topM?.menuGroups__Dynamic?.length > 0) {
                  return (
                    <li
                      data-cy='nav-list-with-dropdown'
                      className='nav-item dropdown mega-menu-wrapper'
                      key={"level0" + idx}
                    >
                      <Link href='/' legacyBehavior>
                        <a
                          className='nav-link'
                          id='navbarDropdown'
                          role='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          {topM.title}
                        </a>
                      </Link>
                      <div
                        className='dropdown-menu mega-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='container'>
                          <div className='py-4 mega-menu-inner'>
                            <div className='row'>
                              {topM.menuGroups__Dynamic.map(function (
                                grp,
                                idx0
                              ) {
                                return (
                                  <div
                                    className='col-md-3'
                                    key={"level1-" + idx0}
                                  >
                                    <div className='list-megamenu-header fw-bold text-uppercase'>
                                      {grp.groupName}
                                    </div>
                                    <ul className='list-megamenu'>
                                      {grp.childs
                                        .map((x) => x.menuItem)
                                        .filter(filter)
                                        .map(function (mn, idx1) {
                                          let url = mn.url;
                                          if (
                                            !mn.url.startsWith("/") &&
                                            !mn.url.startsWith("http")
                                          ) {
                                            url = "/" + mn.url;
                                          }
                                          return (
                                            <li key={idx0 + "-" + idx1}>
                                              <a href={url}>{mn.title}</a>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li className='nav-item' key={"level0" + idx}>
                      <a
                        href={topM.url}
                        target={topM.openInNewTab ? "_blank" : "_self"}
                        rel='noreferrer'
                        className='nav-link'
                      >
                        {topM.title}
                      </a>
                    </li>
                  );
                }
              })}

              <li
                className='nav-item dropdown d-block d-sm-none'
                data-cy='more-menu'
              >
                <a
                  className='nav-link'
                  href='#'
                  role='button'
                  id='dropdownMenuLink'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  More
                </a>

                <ul
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuLink'
                  style={{ right: "0", left: "auto" }}
                >
                  {moreLinks.map(function (mn, idx) {
                    let url = mn.url;
                    if (!mn.url.startsWith("/") && !mn.url.startsWith("http")) {
                      url = "/" + mn.url;
                    }
                    return (
                      <li key={idx}>
                        <a className='dropdown-item' href={url}>
                          {mn.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
            <ul className='navbar-nav ml-auto d-none d-md-flex'>
              <li className='nav-item m-0'>
                <Link
                  href='/search/-/-'
                  className='navbar-search-link d-block px-3 text-white no-decoration text-uppercase'
                  title='Filter Search'
                >
                  {/* <a> */}
                  <Image
                    src='/icons/product-filter.svg'
                    layout='raw'
                    className='svg-filter-white'
                    width='20'
                    height='20'
                    alt='Filter Search'
                    style={{ verticalAlign: "text-bottom" }}
                    priority={true}
                  />{" "}
                  Filter Finder
                  {/* </a> */}
                </Link>
              </li>
              <li className='nav-item m-0'>
                <Link
                  href='/sitesearch/-'
                  className='navbar-search-link d-block px-3'
                  title='Site Search'
                >
                  {/* <a> */}
                  <Image
                    src='/icons/search.svg'
                    layout='raw'
                    className='svg-filter-white'
                    width='20'
                    height='20'
                    alt='Site Search'
                    style={{ verticalAlign: "text-bottom" }}
                    priority={true}
                  />
                  {/* </a> */}
                </Link>
              </li>
              <li className='nav-item m-0'>
                <div className='dropdown'>
                  <a
                    className='btn text-white f-w-n'
                    href='#'
                    role='button'
                    id='dropdownMenuLink'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    style={{ boxShadow: "none" }}
                  >
                    <Image
                      src='/icons/bars.svg'
                      layout='raw'
                      className='svg-filter-white'
                      width='20'
                      height='20'
                      alt='More Menu'
                      style={{ verticalAlign: "unset" }}
                      priority={true}
                    />
                  </a>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuLink'
                    style={{ right: "0", left: "auto" }}
                  >
                    {moreLinks.map(function (mn, idx) {
                      let url = mn.url;
                      if (
                        !mn.url.startsWith("/") &&
                        !mn.url.startsWith("http")
                      ) {
                        url = "/" + mn.url;
                      }
                      return (
                        <li key={idx}>
                          <a className='dropdown-item' href={url}>
                            {mn.title}
                          </a>
                        </li>
                      );
                    })}
                    {/* <li>
                      
                        <a className='dropdown-item' href={'/search/-/-'}>
                           Filter Finder
                        </a>
                      
                    </li> */}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
